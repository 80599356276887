import { Component } from "fq-foundation/Scripts/Component";
new Component().create("Animations", {
    element: `  .f-hero, 
                .f-cta-list,
                .f-cta-list .f-cta--bg-image,
                .f-cta--image-bottom, 
                .f-cta.t--bg-dark-blue.t--partial-diagonal-rtl-white,
                .f-slider.t--default-diagonal-teal
                `,
    shouldAnimate: function (element) {
        var bounding = $(element).get(0).getBoundingClientRect();
        return bounding.top < window.innerHeight;
    },
    init: function () {
        var self = this;
        $(self.element).each(function () {
            if (!$(this).hasClass("animating")) {
                $(this).addClass('animate')
                if (self.shouldAnimate($(this))) {
                    $(this).addClass('animating')
                }
            }
        });
        $(window).on('scroll', function () {
            $(self.element).each(function () {
                if (!$(this).hasClass("animating")) {
                    $(this).addClass('animate')
                    if (self.shouldAnimate($(this))) {
                        $(this).addClass('animating')
                    }
                }
            });
        });
    }
});